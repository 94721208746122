import { intanceAuth } from "../config/develop-config";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";
const baseUrl = `${BASE_URL}/auth`;

export class AuthDatasource {
  static login(email: any) {
    // const app_key = "15782536645_sellcoda_bo";
    return intanceAuth
      .post(`${baseUrl}/auth/login-user-staff`, {
        email: email,
      })
      .then((response) => {
        localStorage.setItem("profile", response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
