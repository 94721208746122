export default {
  logoHeader: require("./media/logos/logo.png"),
  logoSellcoda: require("./media/logos/logoSellcoda-1.png"),
  backIcon: require("./media/icons/backIcon.png"),
  downGrayIcon: require("./media/icons/iconDownGray.png"),
  upWhiteIcon: require("./media/icons/iconUpWhite.png"),
  iconDoubleRight: require("./media/icons/iconDoubleRight.png"),
  iconDoubleLeft: require("./media/icons/iconDoubleLeft.png"),
  iconDoubleRightDisable: require("./media/icons/iconDoubleRightDisable.png"),
  iconDoubleLeftDisable: require("./media/icons/iconDoubleLeftDisable.png"),
  listIcon: require("./media/icons/listIcon.png"),
  syncIcon: require("./media/icons/iconsync.png"),
  writeIcon: require("./media/icons/writeIcon.png"),
  trashIcon: require("./media/icons/trashIcon.png"),
  backLeft: require("./media/icons/backLeft.png"),
  iconClose: require("./media/icons/iconClose.png"),
  iconWaiting: require("./media/icons/hourglass.png"),
  iconCliboard: require("./media/icons/clipboard.png"),
  iconTruck: require("./media/icons/truck.png"),
  iconCheckedTruck: require("./media/icons/checked-truck.png"),
  iconAddImage: require("./media/icons/iconAddImage.png"),
  resultSuccess: require("./media/icons/resultSuccess.png"),
  resultFailed: require("./media/icons/resultFailed.png"),
  infoIcon: require("./media/icons/infoIcon.png"),
  pdfIcon: require("./media/icons/pdfIcon.svg"),
  downloadFileIcon: require("./media/icons/downloadFile.png"),
  ribbonBadgeBlue: require("./media/icons/Ribbon_badge_blue.png"),
  ribbonBadgeRed: require("./media/icons/Ribbon_badge_red.png"),
  viewFileIcon: require("./media/icons/view-doc.png"),
  mainProduct: require("./media/icons/IconMainProduct.png"),
  iconSubProduct: require("./media/icons/IconSubProduct.png"),
};
