import { DisconnectOutlined, ShopOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Form, Button, Radio, Space, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../components/Card/CardContainer";
import Input from "../../components/Input/Input";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import Selects from "../../components/Select/Select";
import Text from "../../components/Text/Text";
import { color } from "../../resource";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import Buttons from "../../components/Button/Button";
import {
  createShopSetting,
  customerSellcodaById,
  getCustomerByTaxNo,
  getShopSettingฺById,
  updateShopSetting,
} from "../../datasource/ShopSettingDatasource";
import { getMasterAddress, getProvince } from "../../datasource/AddressDatasource";
import Swal from "sweetalert2";
import _ from "lodash";

const Header = styled(Col)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 20px;
  align-items: center;
`;

export const AntSelectCustom = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }

  .ant-select-selection-item {
    border-radius: 4px;
    font-family: Sarabun !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
  }
`;

export const CreateShopSetting: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[4] === "create" ? false : true;
  const id: any = pathSplit[3];

  const [center, setCenter] = useState<any>({ lat: 13.736717, lng: 100.523186 });
  const [masterAddr, setMasterAddr] = useState<any>([]);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [subdistrictList, setSubdistrictList] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [shopById, setShopById] = useState<any>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDg4BI3Opn-Bo2Pnr40Z7PKlC6MOv8T598",
    googleMapsClientId: "427194649680-frihsda5p9jjp6no28ijvoa66vrmq64f.apps.googleusercontent.com",
  });

  const containerStyle = {
    width: "100%",
    height: "350px",
  };

  const onLoad = React.useCallback(
    function callback(map: any) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
    },
    [center],
  );
  const getShopById = async () => {
    const res = await getShopSettingฺById(id).then((res) => {
      return res.responseData;
    });
    setShopById(res);
    setCenter({ lat: Number(res.latitude), lng: Number(res.longitude) });
    getMasterProvince();
    getAddress(res?.address.provinceId, res?.address.districtName, res?.address.subdistrictName);
    form.setFieldsValue({
      shopId: res?.shopId,
      title: res?.title,
      firstname: res?.firstname,
      lastname: res?.lastname,
      nickname: res?.nickname,
      shopName: res?.shopName,
      taxNo: res?.taxNo,
      email: res?.email,
      masterAddressId: res?.masterAddressId,
      telephoneFirst: res?.telephoneFirst,
      provinceId: res?.address.provinceName,
      districtId: res?.address.districtName,
      subdistrictId: res?.address.subdistrictName,
      postcode: res?.postcode,
      addressDesc: res?.addressDesc,
      latitude: res?.latitude,
      longitude: res?.longitude,
      isActive: res?.isActive,
      createBy: res.createBy,
    });
  };
  const getCusData = async () => {
    const res = await customerSellcodaById(id);
    let masterAddress: any = "";
    let masterProvince: any = "";
    if (res?.province) {
      masterProvince = await getMasterProvince(res?.province).then((res) => res);
      masterAddress = await getAddress(
        masterProvince?.provinceId,
        res?.district,
        res?.subdistrict,
      ).then((res) => res);
    }
    form.setFieldsValue({
      title: res?.customerToUserShops[0]?.userShop?.nametitle,
      firstname: res?.customerToUserShops[0]?.userShop?.firstname,
      lastname: res?.customerToUserShops[0]?.userShop?.lastname,
      shopName: res?.customerCompany[0]?.customerName,
      taxNo: res?.taxNo,
      email: res?.customerToUserShops[0]?.userShop?.email,
      masterAddressId: masterAddress?.subdistrictId?.masterAddressId || "",
      telephoneFirst: res?.customerToUserShops[0]?.userShop?.telephone,
      provinceId: masterProvince.provinceId || "",
      districtId: masterAddress?.districtId?.districtName || "",
      subdistrictId: masterAddress?.subdistrictId?.subdistrictName || "",
      postcode: masterAddress?.subdistrictId?.postCode,
      addressDesc: res?.address,
      latitude: res?.lat,
      longitude: res?.lag,
    });
    setCenter({ lat: Number(res?.lat) || center.lat, lng: Number(res?.lag) || center.lng });
  };
  const getMasterProvince = async (proNav?: string) => {
    const province = await getProvince().then((res) => {
      return res.responseData;
    });
    setProvinceList(province);
    const provinceId = province.find((y: any) => {
      const map = proNav?.endsWith(y.provinceName);
      if (map) {
        return y.provinceId;
      }
    });
    return provinceId;
  };
  const getAddress = async (proId: number, dis: string, sub: string) => {
    const getAddr = await getMasterAddress(proId).then((res) => {
      return res.responseData;
    });
    setMasterAddr(getAddr);
    const groupsDistrict = _.groupBy(getAddr || [], "districtName");
    const objDistrict = _.map(groupsDistrict, (items, disName) => {
      return {
        districtId: items.find((item: any) => item.districtName === disName).districtId,
        districtName: disName,
      };
    });
    setDistrictList(objDistrict);
    const districtId = await objDistrict.find((y: any) => {
      const map = dis.endsWith(y.districtName);
      if (map) {
        return y.districtId;
      }
    });

    const groupsSubdistrict = _.groupBy(
      getAddr.filter((y: any) => dis.endsWith(y.districtName)) || [],
      "subdistrictName",
    );
    const objSubdistrict = _.map(groupsSubdistrict, (items, disName) => {
      return {
        masterAddressId: items.find((item: any) => item.subdistrictName === disName)
          .masterAddressId,
        subdistrictId: items.find((item: any) => item.subdistrictName === disName).subdistrictId,
        subdistrictName: disName,
        postCode: items.find((item: any) => item.subdistrictName === disName).postcode,
      };
    });
    setSubdistrictList(objSubdistrict);
    const subdistrictId = await objSubdistrict.find((y: any) => {
      const map = sub.endsWith(y.subdistrictName);
      if (map) {
        return y.subdistrictId;
      }
    });
    return { districtId: districtId, subdistrictId: subdistrictId };
  };
  const onChangeProvince = async () => {
    const payload = form.getFieldsValue(true);
    const getAddr = await getMasterAddress(payload.provinceId).then((res) => {
      return res.responseData;
    });
    setMasterAddr(getAddr);
    const groupsDistrict = _.groupBy(getAddr || [], "districtName");
    const objDistrict = _.map(groupsDistrict, (items, disName) => {
      return {
        districtId: items.find((item: any) => item.districtName === disName).districtId,
        districtName: disName,
      };
    });
    form.setFieldsValue({ districtId: "", subdistrictId: "", postcode: "" });
    setDistrictList(objDistrict);
  };
  const onChangeDistrict = async () => {
    const payload = form.getFieldsValue(true);
    const groupsSubdistrict = _.groupBy(
      masterAddr.filter((y: any) => payload.districtId.endsWith(y.districtName)) || [],
      "subdistrictName",
    );
    const objSubdistrict = _.map(groupsSubdistrict, (items, disName) => {
      return {
        masterAddressId: items.find((item: any) => item.subdistrictName === disName)
          .masterAddressId,
        subdistrictId: items.find((item: any) => item.subdistrictName === disName).subdistrictId,
        subdistrictName: disName,
        postCode: items.find((item: any) => item.subdistrictName === disName).postcode,
      };
    });
    form.setFieldsValue({ subdistrictId: "", postcode: "" });
    setSubdistrictList(objSubdistrict);
  };
  const onChangeSubdistrict = async () => {
    const payload = form.getFieldsValue(true);
    const getSubdistrict = masterAddr.find((x: any) =>
      payload.subdistrictId.endsWith(x.subdistrictName),
    );
    form.setFieldsValue({
      masterAddressId: getSubdistrict.masterAddressId,
      postcode: getSubdistrict ? getSubdistrict.postcode || "" : "",
    });
  };
  const onChangeMap = () => {
    const payload = form.getFieldsValue(true);
    setCenter({ lat: Number(payload.latitude), lng: Number(payload.longitude) });
  };

  useEffect(() => {
    !isEdit && id > 0 && getCusData();
    isEdit && getShopById();
    getMasterProvince().then((res) => res);
  }, []);

  const staticData = [
    {
      label: "คุณ",
      value: "คุณ",
      key: "1",
    },
    {
      label: "นาย",
      value: "นาย",
      key: "2",
    },
    {
      label: "นางสาว",
      value: "นางสาว",
      key: "3",
    },
    {
      label: "นาง",
      value: "นาง",
      key: "4",
    },
  ];
  const header = (
    <Header style={{ width: "50%" }}>
      <Row gutter={16}>
        <Col span={2}>
          <ShopOutlined style={{ fontSize: "20px" }} />
        </Col>
        <Col span={22}>
          <Text fontSize={16} color='primary' fontWeight={600}>
            {form.getFieldValue("shopName")}
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16} fontWeight={500}>
            หมายเลขนิติบุคคล :
          </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{form.getFieldValue("taxNo")}</Text>
        </Col>
      </Row>
    </Header>
  );
  const sectionUserShop = (
    <>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            รายละเอียดข้อมูลบุคคล (เจ้าของร้าน)
          </Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={6}>
          <Form.Item
            name='title'
            label='คำนำหน้าชื่อ'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกคำนำหน้าชื่อ",
              },
            ]}
          >
            <Selects data={staticData} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='firstname'
            label='ชื่อจริง'
            rules={[
              {
                required: true,
                message: "กรุณากรอกชื่อจริง",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='lastname'
            label='นามสกุล'
            rules={[
              {
                required: true,
                message: "กรุณากรอกนามสกุล",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='nickname' label='ชื่อเล่น'>
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item name='email' label='อีเมล์'>
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='telephoneFirst'
            label='เบอร์โทรศัพท์'
            rules={[
              {
                required: true,
                message: "กรุณากรอกเบอร์โทร",
              },
              {
                pattern: /^[0-9.]*$/,
                message: "กรุณากรอกตัวเลขเท่านั้น",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const sectionShop = (
    <>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            รายละเอียดข้อมูลร้านค้า
          </Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='shopName'
            label='ชื่อร้านค้า'
            rules={[
              {
                required: true,
                message: "กรุณากรอกชื่อร้านค้า",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='taxNo'
            label='หมายเลขนิติบุคคล'
            rules={[
              {
                required: true,
                message: "กรุณากรอกหมายเลขนิติบุคคล",
              },
              {
                pattern: /^[0-9]*$/,
                message: "กรุณากรอกตัวเลขเท่านั้น",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            ที่อยู่ร้านค้า
          </Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={6}>
          <Form.Item
            name='provinceId'
            label='จังหวัด'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกจังหวัด",
              },
            ]}
          >
            <AntSelectCustom
              showSearch
              options={
                provinceList?.map((x: any) => ({
                  key: x.provinceName,
                  value: x.provinceId,
                  label: x.provinceName,
                })) || []
              }
              onChange={onChangeProvince}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='districtId'
            label='อำเภอ/เขต'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกอำเภอ/เขต",
              },
            ]}
          >
            <AntSelectCustom
              showSearch
              options={
                districtList?.map((x: any) => ({
                  key: x.districtName,
                  value: x.districtName,
                  label: x.districtName,
                })) || []
              }
              onChange={onChangeDistrict}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='subdistrictId'
            label='ตำบล/แขวง'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกตำบล/แขวง",
              },
            ]}
          >
            <AntSelectCustom
              showSearch
              options={
                subdistrictList?.map((x: any) => ({
                  key: x.subdistrictName,
                  value: x.subdistrictName,
                  label: x.subdistrictName,
                })) || []
              }
              onChange={onChangeSubdistrict}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='postcode'
            label='รหัสไปรษณีย์'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกรหัสไปรษณีย์",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='addressDesc'
            label='ที่อยู่ (บ้านเลขที่ หมู่ ซอย ชั้น อาคาร)'
            rules={[
              {
                required: true,
                message: "กรุณากรอกที่อยู่",
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='latitude'
            label='ตำแหน่ง Latitude'
            rules={[
              {
                required: true,
                message: "กรุณากรอก Latitude",
              },
              {
                pattern: /^[0-9.]*$/,
                message: "กรุณากรอกตัวเลขและจุด (.)เท่านั้น",
              },
            ]}
          >
            <Input autoComplete='off' onChange={onChangeMap} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='longitude'
            label='ตำแหน่ง Longitude'
            rules={[
              {
                required: true,
                message: "กรุณากรอก Longitude",
              },
              {
                pattern: /^[0-9.]*$/,
                message: "กรุณากรอกตัวเลขและจุด (.)เท่านั้น",
              },
            ]}
          >
            <Input autoComplete='off' onChange={onChangeMap} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const attachments = (
    <>
      <Row gutter={8}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            อัพโหลดสัญญาเข้าร่วมโครงการ
          </Text>
        </Col>
        <Col>
          <Text fontSize={16}>(ไฟล์ภาพและไฟล์ pdf)</Text>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col style={{ color: color.primary }}>
          <DisconnectOutlined style={{ fontSize: "15px" }} />{" "}
          <Text color='primary'>ดาวน์โหลดเอกสารสัญญา</Text>
        </Col>
      </Row>
      <Row>
        <Button type='primary' icon={<UploadOutlined />}>
          อัพโหลดไฟล์
        </Button>
      </Row>
    </>
  );
  const checkCustomer = async (taxNo: string) => {
    const olds = shopById;
    const news = form.getFieldsValue(true);
    if (olds) {
      const check = olds.taxNo === news.taxNo;
      if (!check) {
        const res = await getCustomerByTaxNo(taxNo).then((res: any) => res.success);
        return res;
      } else {
        return true;
      }
    } else {
      const res = await getCustomerByTaxNo(taxNo).then((res: any) => res.success);
      return res;
    }
  };

  const createShop = async () => {
    const validate = await form.validateFields().catch((e) => {
      return false;
    });
    if (validate !== false) {
      const payload = form.getFieldsValue(true);
      const check = await checkCustomer(payload.taxNo);
      if (check) {
        if (payload?.shopId) {
          const data = { ...payload };
          data.updateBy = userProfile.firstname + " " + userProfile.lastname;
          await updateShopSetting(data).then((res) => {
            if (res.success) {
              setShowModal(false);
              setTimeout(() => {
                navigate("/shopSetting");
              }, 200);
            }
          });
        } else {
          const data = { ...payload };
          data.createBy = userProfile.firstname + " " + userProfile.lastname;
          data.isActive = true;
          await createShopSetting(data).then((res) => {
            if (res.success) {
              setShowModal(false);
              setTimeout(() => {
                navigate("/shopSetting");
              }, 200);
            }
          });
        }
      } else {
        setShowModal(false);
        Swal.fire({
          title: "ร้านค้าที่ต้องการเพิ่ม มีข้อมูลแล้วในระบบ",
          text: "กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง",
          width: 320,
          confirmButtonText: "ตกลง",
          confirmButtonColor: color.primary,
          icon: "warning",
          iconColor: color.error,
          showConfirmButton: true,
        });
      }
    } else {
      setShowModal(false);
    }
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={
          !isEdit && id > 0 ? "เพิ่มร้านค้าจาก Sellcoda" : isEdit ? "แก้ไขร้านค้า" : "เพิ่มร้านค้า"
        }
        showBack
        onBack={() => navigate(`/shopSetting`)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการร้านค้า", path: `/shopSetting` },
              {
                text: "รายละเอียดร้านค้า",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      {!isEdit && id > 0 && (
        <>
          {header}
          <Divider />
        </>
      )}
      <Form form={form} layout='vertical'>
        {sectionUserShop}
        {sectionShop}
        {isLoaded ? (
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} onLoad={onLoad}>
            <MarkerF position={center} />
          </GoogleMap>
        ) : (
          <></>
        )}
        <br />
        {attachments}
        <br />
        {isEdit && (
          <Col span={10}>
            <Form.Item
              name='isActive'
              label='สถานะ'
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุสถานะ",
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Space direction='vertical'>
                  <Radio value={true}>ใช้งาน</Radio>
                  <Radio value={false}>ปิดใช้งาน</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Form>
      <Divider />
      <Row justify='space-between' gutter={12}>
        <Col xl={2} sm={6}>
          <Buttons typeButton='primary-light' title='ยกเลิก' onClick={() => navigate(-1)} />
        </Col>
        <Col xl={20} sm={12}></Col>
        <Col xl={2} sm={6}>
          <Buttons typeButton='primary' title='บันทึก' onClick={() => setShowModal(!showModal)} />
        </Col>
      </Row>
      {showModal && (
        <Modal
          centered
          open={showModal}
          closable={false}
          onOk={createShop}
          onCancel={() => setShowModal(false)}
          destroyOnClose
          cancelText={"ยกเลิก"}
          okText={"ยืนยัน"}
          cancelButtonProps={{ style: { color: color.primary, borderColor: color.primary } }}
        >
          <Text level={2}>
            {isEdit ? "ยืนยันการบันทึกรายละเอียดร้านค้า" : "ยืนยันเพิ่มร้านค้าเข้าระบบ"}
          </Text>
          <br />
          {isEdit ? (
            <>
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดแบรนด์ร้านค้าอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <Text level={5} color='Text3'>
              โปรดตรวจสอบข้อมูลให้ถูกต้อง ก่อนยืนยันการเพิ่มร้านค้า
            </Text>
          )}
        </Modal>
      )}
    </CardContainer>
  );
};
