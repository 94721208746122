import { Badge, Col, Dropdown, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import {
  CaretDownOutlined,
  ExperimentOutlined,
  SearchOutlined,
  ShopOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import { color } from "../../resource";
import styled from "styled-components";
import {
  customerSellcodaById,
  customerSellcodaList,
  getCustomerByTaxNo,
  getShopSetting,
} from "../../datasource/ShopSettingDatasource";
import { getProvince } from "../../datasource/AddressDatasource";
import { dateFormatter } from "../../utility/Formatter";
import NewSelect from "react-select";

const Header = styled(Col)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 20px;
  //display: flex;
  //gap: 16px;
  align-items: center;
  width: "100%";
`;

export const IndexShopSetting: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataState, setDataState] = useState<{ count: number; data: any[] }>({
    count: 0,
    data: [],
  });
  const pageSize = 8;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [provinceId, setProvinceId] = useState<number>();
  const [isActive, setIsActive] = useState<any>("");
  const [cusList, setCusList] = useState<any>();
  const [dataUser, setDataUser] = useState<any>({});
  const [provinceList, setProvinceList] = useState<any>([]);

  const [checkDup, setCheckDup] = useState<boolean>(true);
  const [rowCus, setRowCus] = useState<number>(10);

  const items: any = [
    {
      label: <Text>เพิ่มร้านค้าจาก Sellcoda</Text>,
      key: "1",
      onClick: () => setShowModal(true),
    },
    {
      label: <Text>เพิ่มร้านค้าใหม่</Text>,
      key: "2",
      onClick: () => navigate("/shopSetting/createShopSetting/0/create"),
    },
  ];

  const getShopList = async () => {
    await getShopSetting({
      page,
      take: pageSize,
      search,
      isActive,
      provinceId,
      sortField: "updateDate",
      sortDirection: "DESC",
    }).then((res) => {
      setDataState({ count: res.responseData.count || 0, data: res.responseData.data || [] });
    });
  };
  const getCusSellcoda = async () => {
    await customerSellcodaList({ take: rowCus, page: 1 }).then((res) => {
      setCusList(res || []);
    });
  };
  const getMasterProvince = async () => {
    const province = await getProvince().then((res) => {
      return res.responseData;
    });
    setProvinceList(province || []);
  };

  useEffect(() => {
    getShopList();
    getMasterProvince();
  }, [provinceId, isActive, page, search]);

  useEffect(() => {
    getCusSellcoda();
  }, [rowCus]);

  const selectedCus = async (id: string) => {
    const res = await customerSellcodaById(id);
    setDataUser({
      customerId: res?.customerId,
      customerName: res?.customerCompany[0]?.customerName,
      taxId: res?.taxNo,
      telephone: res?.telephone,
      userName:
        (res?.customerToUserShops[0]?.userShop?.nametitle || "-") +
        " " +
        (res?.customerToUserShops[0]?.userShop?.firstname || " ") +
        " " +
        (res?.customerToUserShops[0]?.userShop?.lastname || " "),
      province: res?.province,
    });
  };

  const checkCustomer = async (taxNo: string) => {
    const res = await getCustomerByTaxNo(taxNo).then((res: any) => res.success);
    return res;
  };

  const columns: any = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "shopNo",
      key: "shopNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5}>{value}</Text>
              <br />
              <Text level={5} color='Text3' fontSize={14}>
                {dateFormatter(row.createDate, true)}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "shopName",
      key: "shopName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5}>{value}</Text>
              <br />
              <Text level={5} color='Text3' fontSize={14}>
                {row.taxNo}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ชื่อเจ้าของร้าน",
      dataIndex: "name",
      key: "name",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5}>{row.title + " " + row.firstname + " " + row.lastname}</Text>
              <br />
              <Text level={5} color='Text3' fontSize={14}>
                {row.nickname}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "เบอร์โทร",
      dataIndex: "telephoneFirst",
      key: "telephoneFirst",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text level={5}>{value}</Text>,
        };
      },
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text level={5}>{row.address.provinceName}</Text>,
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Badge
                count={row.isActive ? "เปิดใช้งาน" : "ปิดใช้งาน"}
                style={{ backgroundColor: row.isActive ? color.success : color.Disable }}
              />
            </Row>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row gutter={8}>
              <Col>
                <Button
                  onClick={() => navigate(`/shopSetting/detailShopSetting/${row.shopId}`)}
                  typeButton='icon'
                  icon={<UnorderedListOutlined />}
                  style={{ padding: "12px" }}
                />
              </Col>
              <Col>
                <Button
                  //onClick={() => navigate(`/shopSetting/createShopSetting/${row.shopId}/edit`)}
                  typeButton='icon'
                  icon={<ExperimentOutlined />}
                  style={{ padding: "12px" }}
                />
              </Col>
            </Row>
          ),
        };
      },
    },
  ];

  const PageTitle = (
    <>
      <Row align='middle' gutter={16}>
        <Col span={20}>
          <Text level={3} fontWeight={700}>
            รายการร้านค้า
          </Text>
        </Col>
        <Col className='gutter-row' span={4}>
          <Dropdown menu={{ items }}>
            <Button
              title='+ เพิ่มร้านค้าเข้าระบบ'
              iconPlacement='right'
              icon={<CaretDownOutlined style={{ color: "white" }} />}
            />
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row align='middle' gutter={16}>
        <Col span={18} style={{ paddingTop: "8px" }}>
          <Input
            allowClear
            placeholder='ค้นหาชื่อสินค้า / ชื่อเจ้าของร้าน / เบอร์โทรศัพท์'
            prefix={<SearchOutlined style={{ color: "grey" }} />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            placeholder='ทุกจังหวัด'
            data={
              provinceList?.map((x: any) => ({
                key: x.provinceId,
                value: x.provinceId,
                label: x.provinceName,
              })) || []
            }
            style={{ width: "100%" }}
            onChange={(e) => {
              setPage(1);
              setProvinceId(e);
            }}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            placeholder='เลือกสถานะ'
            data={[
              { key: true, value: true, label: "เปิดใช้งาน" },
              { key: false, value: false, label: "ปิดใช้งาน" },
            ]}
            style={{ width: "100%" }}
            onChange={(e) => {
              setPage(1);
              setIsActive(e);
            }}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <CardContainer>
        {PageTitle}
        <br />
        <Table
          columns={columns}
          dataSource={dataState.data || []}
          pagination={{
            position: ["bottomCenter"],
            pageSize,
            current: page,
            total: dataState.count,
            onChange: (p) => setPage(p),
            showSizeChanger: false,
          }}
        />
      </CardContainer>
      {showModal && (
        <Modal
          open={showModal}
          closable={false}
          title={<Text fontWeight={600}>เพิ่มร้านค้าจากระบบ Sellcoda</Text>}
          centered
          onCancel={() => {
            setCheckDup(true);
            setShowModal(false);
            setDataUser("");
          }}
          destroyOnClose
          cancelText={"ยกเลิก"}
          okText={"ยืนยัน"}
          okButtonProps={{
            style: {
              color: color.white,
              borderColor:
                checkDup && Object.keys(dataUser).length > 0 ? color.primary : color.Disable,
              backgroundColor:
                checkDup && Object.keys(dataUser).length > 0 ? color.primary : color.Disable,
            },
          }}
          onOk={() =>
            checkDup &&
            Object.keys(dataUser).length > 0 &&
            navigate("/shopSetting/createShopSetting/" + `${dataUser.customerId}/create`)
          }
          cancelButtonProps={{
            style: { color: color.primary, borderColor: color.primary },
          }}
        >
          <Row>
            <Col span={24}>
              <NewSelect
                placeholder='ค้นหาชื่อร้าน/หมายเลขนิติบุคคล'
                isSearchable
                isClearable
                onChange={async (e: any) => {
                  setPage(1);
                  if (!e) {
                    setDataUser("");
                    setCheckDup(true);
                  } else {
                    const find = cusList.data.find((x: any) => `${x.customerId}` === `${e.value}`);
                    const check = await checkCustomer(find.taxNo);
                    if (check) {
                      selectedCus(e.value);
                    } else {
                      setDataUser("");
                    }
                    setCheckDup(check);
                  }
                }}
                options={
                  cusList?.data?.map((cus: any) => ({
                    key: cus.customerCompany[0].customerId,
                    label: cus.customerCompany[0].customerName,
                    value: cus.customerCompany[0].customerId,
                  })) || []
                }
                onMenuScrollToBottom={() => setRowCus(rowCus + 10)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: !checkDup ? color.error : color.Disable,
                  }),
                }}
              />
            </Col>
          </Row>
          <br />
          {Object.keys(dataUser).length > 0 && checkDup ? (
            <Header>
              <Row gutter={16}>
                <Col span={2}>
                  <ShopOutlined style={{ fontSize: "20px" }} />
                </Col>
                <Col span={22}>
                  <Text fontSize={16} color='primary' fontWeight={600}>
                    {dataUser.customerName}
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Text fontSize={14} fontWeight={500}>
                    หมายเลขนิติบุคคล
                  </Text>
                  <br />
                  <Text fontSize={14}>{dataUser.taxId}</Text>
                </Col>
                <Col span={12}>
                  <Text fontSize={14} fontWeight={500}>
                    เบอร์โทรศัพท์
                  </Text>
                  <br />
                  <Text fontSize={14}>{dataUser.telephone}</Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Text fontSize={14} fontWeight={500}>
                    เจ้าของร้าน
                  </Text>
                  <br />
                  <Text fontSize={14}>{dataUser.userName}</Text>
                </Col>
                <Col span={12}>
                  <Text fontSize={14} fontWeight={500}>
                    จังหวัด
                  </Text>
                  <br />
                  <Text fontSize={14}>{dataUser.province}</Text>
                </Col>
              </Row>
            </Header>
          ) : Object.keys(dataUser).length === 0 && !checkDup ? (
            <Text color='error' fontSize={16}>
              ร้านค้าที่เลือกมีข้อมูลแล้วในระบบ กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง
            </Text>
          ) : (
            <></>
          )}
        </Modal>
      )}
    </>
  );
};
