import axios from "axios";

export const REACT_APP_NAV_DEV_URL = "https://api-dev-sellcoda.iconkaset.com/nav";
export const NAV_URL =
  process.env.REACT_APP_ENV === "development"
    ? REACT_APP_NAV_DEV_URL
    : process.env.REACT_APP_NAV_DEV_URL;

export const REACT_APP_DEV_URL = "https://iconkasetshop-api-dev.iconkaset.com";
export const BASE_URL =
  process.env.REACT_APP_ENV === "development" ? REACT_APP_DEV_URL : process.env.REACT_APP_URL_HOST;

export const REACT_APP_SELLCODA_DEVELOP_URL = "https://sellcoda-api-dev.iconkaset.com";
export const SELLCODA_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? REACT_APP_SELLCODA_DEVELOP_URL
    : process.env.REACT_APP_SELLCODA_URL_HOST;

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token") || "";
    const newConfig = {
      ...config,
      headers: {
        accept: "application/json",
        Authorization: token ? `Bearer ` + JSON.parse(token) : "",
      },
    };

    return newConfig;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      const url = window.location.href;
      const arr = url.split("/");
      const resultUrlHost = arr[0] + "//" + arr[2];

      window.location.href =
        "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        resultUrlHost;
    }
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      const url = window.location.href;
      const arr = url.split("/");
      const resultUrlHost = arr[0] + "//" + arr[2];

      window.location.href =
        "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        resultUrlHost;
    }
    return error;
  },
);

const customHttp = axios.create({});
const tokenSell = process.env.REACT_APP_SELLCODA_TOKEN;
customHttp.interceptors.request.use(async (config) => {
  const newConfig = {
    ...config,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ` + tokenSell,
    },
  };

  return newConfig;
});

export const httpClient = axios;
export const customClient = customHttp;
export const intanceAuth = axios.create({
  baseURL: BASE_URL,
});
