import { EditOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import { CardContainer } from "../../components/Card/CardContainer";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import Text from "../../components/Text/Text";
import { getShopSettingฺById } from "../../datasource/ShopSettingDatasource";
import color from "../../resource/color";

const Header = styled(Col)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 20px;
  align-items: center;
`;

export const DetailShopSetting: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id: any = pathSplit[3];

  const [data, setData] = useState<any>();
  const getShopById = async () => {
    const res = await getShopSettingฺById(id).then((res) => {
      return res.responseData;
    });
    setData(res);
  };

  useEffect(() => {
    getShopById();
  }, []);

  const detailShop = (
    <Header style={{ width: "100%", padding: "20px", height: "260px" }}>
      <Text fontSize={16} fontWeight={600}>
        ข้อมูลร้านค้า
      </Text>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>ชื่อร้านค้า : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{data?.shopName}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>หมายเลขนิติบุคคล : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{data?.taxNo}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>ที่อยู่ : </Text>
        </Col>
        <Col span={12}>
          <Text
            fontSize={16}
          >{`${data?.addressDesc} ${data?.address.districtName} ${data?.address.subdistrictName} ${data?.address.provinceName} ${data?.postcode}`}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>พิกัดตำแหน่ง : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>
            {data?.latitude || "-"}, {data?.longitude || "-"}
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>สัญญาเข้าร่วมโครงการ : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16} color='primary'>
            ดูสัญญาเข้าร่วมโครงการ
          </Text>
        </Col>
      </Row>
    </Header>
  );
  const detailUser = (
    <Header style={{ width: "100%", padding: "20px", height: "260px" }}>
      <Text fontSize={16} fontWeight={600}>
        ข้อมูลเจ้าของร้าน
      </Text>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>เจ้าของร้านค้า : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{`${data?.title || "-"} ${data?.firstname || ""} ${
            data?.lastname || ""
          }`}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>ชื่อเล่น : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{data?.nickname || "-"}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>อีเมล์ : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{data?.email || "-"}</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Text fontSize={16}>เบอร์โทรศัพท์ : </Text>
        </Col>
        <Col span={12}>
          <Text fontSize={16}>{data?.telephoneFirst}</Text>
        </Col>
      </Row>
    </Header>
  );

  return (
    <>
      <CardContainer>
        <PageTitleNested
          title={"รายละเอียดร้านค้า"}
          showBack
          onBack={() => navigate(`/shopSetting`)}
          customBreadCrumb={
            <BreadCrumb
              data={[
                { text: "รายการร้านค้า", path: `/shopSetting` },
                {
                  text: "รายละเอียดร้านค้า",
                  path: window.location.pathname,
                },
              ]}
            />
          }
        />
      </CardContainer>
      <br />
      <CardContainer>
        <Row align='middle' gutter={16}>
          <Col span={20}>
            <Text level={3} fontWeight={700}>
              รายละเอียดร้านค้า
            </Text>
          </Col>
          <Col className='gutter-row' span={4}>
            <Button
              typeButton='primary-light'
              icon={<EditOutlined />}
              title='แก้ไขรายละเอียด'
              height={40}
              onClick={() => navigate(`/shopSetting/createShopSetting/${id}/edit`)}
            />
          </Col>
        </Row>
        <br />
        <Row justify={"space-between"} gutter={8}>
          <Col span={12}>{detailShop}</Col>
          <Col span={12}>{detailUser}</Col>
        </Row>
        <br />
      </CardContainer>
    </>
  );
};
