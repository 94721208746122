import React, { Component } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import Text from "../../components/Text/Text";
import { getCompanyName } from "../../utility/CompanyName";

export default class HomePage extends Component<any> {
  render() {
    const profile = JSON.parse(localStorage.getItem("profile") || "{}");
    const { company, firstname, lastname, role } = profile;

    return (
      <CardContainer>
        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{ height: "calc(100vh - 168px)" }}
        >
          HOME
        </div>
      </CardContainer>
    );
  }
}
