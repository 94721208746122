import { Col, Divider, message, Row, Upload, Form, Modal, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../components/Card/CardContainer";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import Text from "../../components/Text/Text";
import { color } from "../../resource";
import Button from "../../components/Button/Button";
import { FlexRow } from "../../components/Container/Container";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ProductEntity } from "../../entities/ProductEntity";
import image from "../../resource/image";

const AddProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 32px 50px;
  margin: 24px 0 0;

  cursor: pointer;

  background: ${color["background1"]};
  border: 1px dashed ${color["primary"]};
  border-radius: 12px;
`;

export const AddProduct: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;

  const [items, setItems] = useState<ProductEntity[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string[]>();
  const [showModal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!showModal);
  };

  const onSubmit = async () => {
    console.log("submit");
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={"เพิ่มสินค้า ICP"}
        showBack
        onBack={() => navigate(`/products`)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการสินค้า 1Finity", path: `/products` },
              {
                text: "เพิ่มสินค้า ICP",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      <Row align='bottom'>
        <Col span={12}>
          <Row align='middle'>
            <Text level={2} fontWeight={700}>
              รายการสินค้าที่เลือก&nbsp;&nbsp;
            </Text>
            <Text level={5} color='Text3'>
              ทั้งหมด 2 รายการ
            </Text>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify='end'>
            <FlexRow align='center'>
              <Button
                title='ลบสินค้า'
                icon={<DeleteOutlined />}
                disabled={!selectedProduct?.length}
                typeButton={!selectedProduct?.length ? "disabled" : "danger"}
              />
              <Divider type='vertical' style={{ margin: "0 12px" }} />
              <Button title={"เพิ่มสินค้า"} icon={<PlusOutlined />} />
            </FlexRow>
          </Row>
        </Col>
      </Row>
      <Form form={form} layout='vertical' onFinish={onSubmit}>
        <div style={{ minHeight: 360 }}>
          {items.length <= 0 ? (
            <AddProductContainer onClick={toggleModal}>
              <img style={{ width: 72, margin: 16 }} src={image.product_box} />
              <Text level={items.length <= 0 ? 4 : 5} color='primary'>
                +&nbsp;เพิ่มสินค้า
              </Text>
              <Text level={6} color='Text1'>
                กรุณาเพิ่มรายการสินค้าที่ต้องการเข้าระบบ
              </Text>
            </AddProductContainer>
          ) : (
            <></>
          )}
        </div>
        <Divider />
        <Row justify='space-between'></Row>
      </Form>
    </CardContainer>
  );
};
