import React from "react";
import styled from "styled-components";

import { Input as InputAnt, InputProps } from "antd";

interface InputPropsStyled {
  width?: string;
  align?: "left" | "center" | "right";
}
const InputStyled = styled(InputAnt)<InputPropsStyled>`
  /* height: ${(props) => props.height || "40px"}; */

  width: ${(props) => props.width || "100%"};
  font-family: Sarabun !important;

  text-align: ${(props) => props.align || "left"};

  .ant-input-group-wrapper {
    padding: 0px !important;
  }
  .ant-input-wrapper.ant-input-group {
    padding: 0px !important;
    height: 40px !important;

    .ant-input {
      height: 40px !important;
    }
  }
`;

interface Props extends InputProps, InputPropsStyled {
  onChange?: (v: any) => void;
  width?: string;
  align?: "left" | "center" | "right";
}
function InputGroup({ ...props }: Props): JSX.Element {
  return <InputStyled {...props} />;
}

export default InputGroup;
