import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL, httpClient, customClient, SELLCODA_BASE_URL } from "../config/develop-config";

const baseUrl = `${BASE_URL}/auth`;

const getShopSetting = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/onefinity-shop`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const customerSellcodaList = async (params: object) => {
  // console.log(process.env);
  return await customClient
    .get(`${SELLCODA_BASE_URL}/auth/customer/get-all-customer`, {
      params,
    })
    .then((res: AxiosResponse) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((err) => console.log(err));
};
const customerSellcodaById = async (cusId: string) => {
  return await customClient
    .get(`${SELLCODA_BASE_URL}/auth/customer/get-customer?customerId=${cusId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const createShopSetting = async (params: object) => {
  return await httpClient
    .post(`${baseUrl}/onefinity-shop`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getShopSettingฺById = async (shopId: string) => {
  return await httpClient
    .get(`${baseUrl}/onefinity-shop/${shopId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateShopSetting = async (params: object) => {
  return await httpClient
    .patch(`${baseUrl}/onefinity-shop`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getCustomerByTaxNo = async (taxNo: string) => {
  return await httpClient
    .get(`${baseUrl}/onefinity-shop/tax-no/${taxNo}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  getShopSetting,
  customerSellcodaList,
  customerSellcodaById,
  createShopSetting,
  getShopSettingฺById,
  updateShopSetting,
  getCustomerByTaxNo,
};
