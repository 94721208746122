import { EditOutlined, SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Badge, Col, Dropdown, Form, Image, Row, Table, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../../utility/Formatter";
import { color } from "../../resource";
import Select from "../../components/Select/Select";
import image from "../../resource/image";
import { FlexCol } from "../../components/Container/Container";
import icon from "../../resource/icon";

export const ProductsIndex: React.FC = () => {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<{ count: number; data: any[]; countStatus: any }>({
    count: 0,
    data: [],
    countStatus: [],
  });
  const pageSize = 8;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [isActive, setIsActive] = useState<any>("");
  const [statusFilter, setStatusFilter] = useState<string>();

  const resetPage = () => setPage(1);

  const getData = async () => {
    const mockData = {
      productName: "ไดนาคลอร์",
      productDesc: "ผลิตภัณฑ์กำจัดวัชพืช",
      size: "1 ลิตร",
      sizePerPack: "1 ลัง 20 ขวด",
      unitPrice: "฿ 280.00",
      status: "Active",
      marketPrice: "฿ 5,400.00",
      statusICK: "เปิดใช้งาน",
    };
    const data = Array.from({ length: 5 }, (v, i) => i + 1).map((e, id) => ({ id, ...mockData }));
    setDataState({ count: 100, data, countStatus: [] });
  };

  useEffect(() => {
    getData();
  }, [search, isActive]);

  const createItems = [
    {
      key: "1",
      label: (
        <a target='_blank' rel='noopener noreferrer' href='/products/create/int'>
          เพิ่มสินค้า ICP
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target='_blank' rel='noopener noreferrer' href='/products/create/ext'>
          เพิ่มสินค้าแบรนด์อื่นๆ
        </a>
      ),
    },
  ];

  const PageTitle = (
    <Row align='middle' gutter={16}>
      <Col span={3}>
        <Text level={3} fontWeight={700}>
          สินค้า 1Finity
        </Text>
      </Col>
      <Col span={17} />
      <Col span={3}>
        <Dropdown menu={{ items: createItems }}>
          <Button
            type='primary'
            title='+ เพิ่มสินค้าเข้าระบบ'
            height={40}
            // onClick={() => navigate("/products/create")}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </Col>
    </Row>
  );

  const tabsItems = [
    {
      label: `สินค้า ICP (${dataState.count})`,
      key: "ICP",
    },
    {
      label: `สินค้าแบรนด์อื่นๆ (${dataState.count})`,
      key: "ETC",
    },
  ];

  const columns: any = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      width: 320,
      render: (value: any, row: any, index: number) => {
        return (
          <Row align='middle'>
            <Col span={6}>
              <Image
                src={row?.image || image.emptyProductBrand}
                height={60}
                width={60}
                style={{ borderRadius: "100px", objectFit: "cover" }}
              />
            </Col>
            <Col span={18}>
              <FlexCol>
                <Text>{value}</Text>
                <Text color='Text3' level={6}>
                  {row?.productDesc}
                </Text>
              </FlexCol>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "ขนาด/หน่วย",
      dataIndex: "size",
      key: "size",
      width: 132,
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
    {
      title: "ขนาด/แพ็ค",
      dataIndex: "sizePerPack",
      key: "sizePerPack",
      width: 182,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={6} color='Text3'>
                <img src={icon.mainProduct} />
                &nbsp;
                {value}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "แบรนด์สินค้า",
      dataIndex: "brand",
      key: "brand",
      width: 194,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={6} color='Text3'>
                <img
                  src={"https://www.เคมีเกษตรไทย.com/wp-content/uploads/2017/11/ICP-LADDA.jpg"}
                  style={{ borderRadius: "50%", width: 28, height: 28 }}
                />
                &nbsp; ไอ ซี พี ลัดดา
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ราคา/หน่วย",
      dataIndex: "unitPrice",
      key: "unitPrice",
      fixed: "right",
      width: 152,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={6} color='Text3'>
                {value}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะ Nav",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 132,
      render: (value: any, row: any, index: number) => {
        return {
          children: <Tag color={color.success}>{value}</Tag>,
        };
      },
    },
    {
      title: "ราคา/แพ็ค",
      dataIndex: "marketPrice",
      key: "marketPrice",
      fixed: "right",
      width: 132,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5} color='primary' fontWeight={700}>
                {value}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะ ICK",
      dataIndex: "statusICK",
      key: "statusICK",
      fixed: "right",
      width: 132,
      render: (value: any, row: any, index: number) => {
        return {
          children: <Tag color={color.success}>{value}</Tag>,
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 88,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Col span={6}>
                <Button
                  onClick={() => navigate(`/products/${row.id}`)}
                  typeButton='icon'
                  icon={<UnorderedListOutlined />}
                  style={{ padding: "12px" }}
                />
              </Col>
            </Row>
          ),
        };
      },
    },
  ];

  return (
    <CardContainer>
      {PageTitle}
      <br />
      <Tabs
        items={tabsItems}
        onChange={(key: string) => {
          setStatusFilter(key === "ALL" ? undefined : key);
          resetPage();
        }}
      />
      <Form>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name='search'>
              <Input prefix={<SearchOutlined />} placeholder='ค้นหาชื่อสินค้า' />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='group'>
              <Select placeholder='ทุกกลุ่มสินค้า' data={[]} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='brand'>
              <Select placeholder='ทุกแบรนด์สินค้า' data={[]} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='status'>
              <Select placeholder='สถานะทั้งหด' data={[]} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        columns={columns}
        dataSource={dataState?.data || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize,
          current: page,
          total: dataState.count,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        scroll={{ x: "100%" }}
      />
    </CardContainer>
  );
};
