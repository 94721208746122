import React, { useState, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Tag, Card, Button, Image, Tabs, Table, Checkbox, Divider, Modal } from "antd";
import { CardContainer } from "../../components/Card/CardContainer";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { nameFormatter, priceFormatter } from "../../utility/Formatter";
import Text from "../../components/Text/Text";
import { BrandEntity } from "../../entities/BrandEntity";
import { useEffectOnce } from "react-use";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import image from "../../resource/image";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import { ProductEntity } from "../../entities/ProductEntity";
import styled from "styled-components";
import { ProductCategoryEntity } from "../../entities/ProductCategoryEntity";
import TextArea from "antd/lib/input/TextArea";
import Permission from "../../components/Permission/Permission";
import { color } from "../../resource";
import Buttons from "../../components/Button/Button";
import icon from "../../resource/icon";

const Container = styled.div`
  margin: 32px 0px 10px 0px;
`;

const ProdImage = styled.img`
  width: 136px;
  height: 136px;
  border-radius: 12px;
  object-fit: contain;
`;

interface DescProps {
  label: string;
  value: ReactNode;
}

const ProdDesc = ({ label, value }: DescProps) => {
  return (
    <Row align='middle' style={{ padding: "8px 0px" }}>
      <Col xl={6} sm={8}>
        <Text level={5} color='Text3'>
          {label}
        </Text>
      </Col>
      <Col xl={18} sm={16}>
        <Text level={5} color='Text1'>
          {value || "-"}
        </Text>
      </Col>
    </Row>
  );
};

export const ProductDetail: React.FC = (props: any) => {
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isFreebie = pathSplit[2] === "freebies";
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;
  const id = parseInt(pathSplit[3]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<"product" | "shop">("product");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffectOnce(() => {
    fetchProduct();
  });

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const data = [{}];
      // if (isFreebie) {
      //   data = await getProductFreebieDetail(id);
      // } else {
      //   data = await getProductDetail(id);
      // }
      setDataState(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const {
    baseUOM,
    commonName,
    //company,
    createDate,
    description,
    inventoryGroup,
    marketPrice,
    packSize,
    packingUOM,
    productBrand,
    productBrandId,
    productCategory,
    productCategoryId,
    productCodeNAV,
    productGroup,
    productId,
    productImage,
    productLocation,
    productName,
    productStatus,
    productStrategy,
    qtySaleUnit,
    saleUOM,
    saleUOMTH,
    unitPrice,
    updateBy,
    updateDate,
    productFreebiesId,
    productFreebiesCodeNAV,
    productFreebiesImage,
  } = dataState || {};

  const dataGroup1 = [
    {
      label: "ชื่อแบรนด์สินค้า (Product Brand)",
      value: (productBrand as BrandEntity)?.productBrandName,
    },
    {
      label: "กลุ่มสินค้า (Product Group)",
      value: productGroup,
    },
    {
      label: "ชื่อทางการค้า (Tradename)",
      value: productName,
    },
    {
      label: "ชื่อสามัญ (Common Name)",
      value: commonName,
    },
  ];
  const dataGroup2 = [
    {
      label: "ปริมาณสินค้า / หน่วย",
      value: commonName,
    },
    {
      label: "ราคากลาง (Base price)",
      value: commonName,
    },
  ];
  const dataGroup3 = [
    {
      label: "ปริมาณสินค้า / แพ็ค",
      value: commonName,
    },
    {
      label: "ราคากลาง (Base price)",
      value: commonName,
    },
    {
      label: "สินค้าใช้กับโดรน",
      value: commonName ? "ใช้ได้" : "ใช้ไม่ได้",
    },
    {
      label: "คุณสมบัติและ ประโยชน์",
      value: commonName,
    },
  ];

  const PageTitle = () => {
    return (
      <PageTitleNested
        title='รายละเอียดสินค้า'
        showBack
        onBack={() => navigate(`/${pathSplit[1]}/${pathSplit[2]}`)}
        extraTitle={
          productStatus && <Tag color={color.success}>{nameFormatter(productStatus)}</Tag>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายชื่อสินค้า", path: `/${pathSplit[1]}/${pathSplit[2]}` },
              { text: "รายละเอียดสินค้า", path: window.location.pathname },
            ]}
          />
        }
      />
    );
  };
  const tabsItems = [
    {
      label: "รายละเอียดสินค้า ",
      key: "product",
    },
    {
      label: "ร้านค้าที่มีสินค้า ",
      key: "shop",
    },
  ];

  const submit = async () => {
    console.log("submit");
  };

  return loading ? (
    <div className='container '>
      <Card loading />
    </div>
  ) : (
    <>
      <div className='container '>
        <CardContainer>
          <PageTitle />
          <br />
          <Tabs
            items={tabsItems}
            onChange={(key: string) => {
              setSelectedTab((key as "product" | "shop") || "product");
            }}
            defaultValue={selectedTab}
          />
          <>
            <Container>
              <Row gutter={8} justify={"space-between"}>
                <Col>
                  <Image
                    src={
                      (isFreebie ? productFreebiesImage : productImage) || image.product_no_image
                    }
                    style={{
                      width: "136px",
                      height: "136px",
                      objectFit: "contain",
                    }}
                  />
                </Col>
                <Col>
                  <Buttons
                    typeButton='primary-light'
                    title='แก้ไขสินค้า'
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/products/edit/${productId}`)}
                  />
                </Col>
              </Row>
            </Container>
            <Container>
              {dataGroup1.map((p: DescProps, i) => (
                <ProdDesc {...p} key={i} />
              ))}
            </Container>
            <Container>
              <Row>
                <Text color='secondary' fontWeight={700}>
                  <img src={icon.mainProduct} />
                  &nbsp; สินค้าต่อหน่วย&nbsp;(Unit&nbsp;Size)
                </Text>
              </Row>
              <br />
              {dataGroup2.map((p: DescProps, i) => (
                <ProdDesc {...p} key={i} />
              ))}
            </Container>
            <Container>
              <Row>
                <Text color='secondary' fontWeight={700}>
                  <img src={icon.iconSubProduct} />
                  &nbsp;สินค้าต่อแพ็ค&nbsp;(Package&nbsp;Size)
                </Text>
              </Row>
              <br />
              {dataGroup3.map((p: DescProps, i) => (
                <ProdDesc {...p} key={i} />
              ))}
            </Container>
          </>
          <Divider />
          {isEdit && (
            <Row justify='space-between' gutter={12}>
              <Col xl={3} sm={6}>
                <Buttons
                  typeButton='danger'
                  title='ยกเลิกการแก้ไข'
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          <Text fontWeight={700} level={4}>
                            ยืนยันการยกเลิก
                          </Text>
                          <br />
                          <Text level={6}>
                            โปรดตรวจสอบรายละเอียดร้านค้าอีกครั้ง ก่อนการกดยืนยันยกเลิกการแก้ไข
                          </Text>
                        </>
                      ),
                      okText: "",
                      cancelText: "",
                      onOk: async () => {
                        setIsEdit(!isEdit);
                      },
                    });
                  }}
                />
              </Col>
              <Col xl={18} sm={12}></Col>
              <Col xl={3} sm={6}>
                <Buttons
                  typeButton='primary'
                  title='บันทึก'
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          <Text fontWeight={700} level={4}>
                            ต้องการยืนยันการบันทึกรายการร้านค้า
                          </Text>
                          <br />
                          <Text level={6}>
                            โปรดตรวจสอบรายละเอียดร้านค้าอีกครั้งก่อนกดยืนยัน
                            เพราะอาจส่งผลต่อการแสดงผลในระบบแอปพลิเคชัน
                          </Text>
                        </>
                      ),
                      okText: "",
                      cancelText: "",
                      onOk: async () => {
                        submit();
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          )}
        </CardContainer>
      </div>
    </>
  );
};
