import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthPage } from "./pages/AuthPage/AuthPage";
import ProtectRoute from "./ProtectRoute";
import { Spin } from "antd";
import { profileAtom } from "./store/ProfileAtom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { roleAtom } from "./store/RoleAtom";
import { roleDatasource } from "./datasource/RoleDatasource";
import ErrorLoginPage from "./pages/ErrorPage/ErrorLoginPage";
import PageNotFound from "./pages/HttpError/PageNotFound";
import HomePage from "./pages/HomePage/HomePage";
import { IndexBrandSetting } from "./pages/BrandSetting/Index";
import { CreateBrandSetting } from "./pages/BrandSetting/CreateBrandSetting";
import { ProductsIndex } from "./pages/ProductSetting/Index";
import { AddProduct } from "./pages/ProductSetting/AddProduct";
import { ProductDetail } from "./pages/ProductSetting/ProductDetail";
import { CreateProduct } from "./pages/ProductSetting/CreateProduct";
import { IndexShopSetting } from "./pages/ShopSetting/Index";
import { CreateShopSetting } from "./pages/ShopSetting/CreateShopSetting";
import { DetailShopSetting } from "./pages/ShopSetting/DetailShopSetting";

interface IRoute {
  path: string;
  element: JSX.Element;
  permission: {
    name: string;
    action: string;
  } | null;
  nestedRoutes: {
    path: string;
    element: JSX.Element;
    permission: {
      name: string;
      action: string;
    } | null;
  }[];
  index?: boolean;
}
export const protectRoutesData: IRoute[] = [
  {
    path: "/",
    element: <HomePage />,
    permission: null,
    nestedRoutes: [],
    index: false,
  },
  {
    path: "/home",
    element: <HomePage />,
    permission: null,
    nestedRoutes: [],
    index: false,
  },
  {
    path: "/brandSetting/*",
    element: <Outlet />,
    permission: null,
    nestedRoutes: [
      {
        path: "",
        element: <IndexBrandSetting />,
        permission: null,
      },
      {
        path: "createBrandSetting/:id",
        element: <CreateBrandSetting />,
        permission: null,
      },
    ],
    index: false,
  },
  {
    path: "/products/*",
    element: <Outlet />,
    permission: null,
    nestedRoutes: [
      {
        path: "",
        element: <ProductsIndex />,
        permission: null,
      },
      {
        path: "create/int",
        element: <AddProduct />,
        permission: null,
      },
      {
        path: "create/ext",
        element: <CreateProduct />,
        permission: null,
      },
      {
        path: ":id",
        element: <ProductDetail />,
        permission: null,
      },
      {
        path: "edit/:id",
        element: <CreateProduct />,
        permission: null,
      },
    ],
    index: false,
  },
  {
    path: "/shopSetting/*",
    element: <Outlet />,
    permission: null,
    nestedRoutes: [
      {
        path: "",
        element: <IndexShopSetting />,
        permission: null,
      },
      {
        path: "createShopSetting/:id/:type",
        element: <CreateShopSetting />,
        permission: null,
      },
      {
        path: "detailShopSetting/:id",
        element: <DetailShopSetting />,
        permission: null,
      },
    ],
    index: false,
  },
];

const WebRoutes: React.FC<any> = () => {
  const [profileRecoil, setProfileRecoil] = useRecoilState(profileAtom);
  const setRole = useSetRecoilState(roleAtom);
  const [loading, setLoading] = useState<boolean>(true);
  const profile: any = localStorage.getItem("profile");

  useEffect(() => {
    const getRoleData = async (roleId: string) => {
      try {
        const roleData = await roleDatasource.getRoleById(roleId);
        setRole(roleData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const getUserData = async () => {
      setLoading(true);
      setProfileRecoil(JSON.parse(profile) || null);
      //setProfileRecoil(null);
      // if (JSON.parse(profile)) {
      //   getRoleData(JSON.parse(profile).roleId);
      // }
    };

    if (profile) {
      getUserData();
    } else {
      setTimeout(() => {
        setLoading(false);
        setProfileRecoil(null);
        setRole(null);
      }, 2000);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Spin size='large' />
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          {profileRecoil === null ? (
            <>
              <Route index element={<AuthPage />} />
              <Route path='/' element={<AuthPage />} />
              <Route path='/ErrorLoginPage' element={<ErrorLoginPage />} />
              <Route path='*' element={<PageNotFound />} />
            </>
          ) : (
            <Route element={<ProtectRoute isAuth={!!profileRecoil} />}>
              {protectRoutesData.map((route, index) => {
                if (route.nestedRoutes.length < 1) {
                  return <Route key={index} path={route.path} element={route.element} />;
                } else {
                  return (
                    <Route key={index} path={route.path} element={route.element}>
                      {route.nestedRoutes.map((nestedRoute, idx) => {
                        return (
                          <Route key={idx} path={nestedRoute.path} element={nestedRoute.element} />
                        );
                      })}
                    </Route>
                  );
                }
              })}
              <Route path='*' element={<PageNotFound />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default WebRoutes;
