import { AxiosResponse } from "axios";
import { httpClient, BASE_URL } from "../config/develop-config";

const baseUrl = `${BASE_URL}/auth`;

const getProvince = async () => {
  return await httpClient
    .get(`${baseUrl}/master-address`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getMasterAddress = async (proId: number) => {
  return await httpClient
    .get(`${baseUrl}/master-address/${proId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export { getProvince, getMasterAddress };
